<template>
  <div>
    <Stepper
        :tabs="tabs"
        :captions="captions"
        :complete="complete"
        @position="stepChange($event)"
        ref="stepper"
        v-if="!!customer"
        vertical
    >

      <template v-slot:BasicInfo>
        <BasicInfo
            v-model="basicFormFields"
            :customer="customer"
            ref="basicInfoForm">

        </BasicInfo>
      </template>

    </Stepper>
    <div class="text-left py-2">
      <v-btn color="primary" class="mb-2" @click="saveAndGoToFieldSurvey">Submit and fill field survey form</v-btn>
    </div>
    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        color="green"
        elevation="24"
    >
      You have successfully onboarded the farmer
    </v-snackbar>

  </div>
</template>

<script>
import {v4 as uuidv4} from "uuid";
import BasicInfo from "@/views/partners/eco_bw/views/register/BasicInfo";
import Stepper from "@/components/Stepper";
import StepperValidationSection from "@/views/partners/eco_bw/components/StepperValidationSection";

export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },

  components: {
    BasicInfo,
    Stepper,
    StepperValidationSection
  },

  data: function () {
    return {
      valid: false,
      snackbar: false,
      timeout: 2000,
      valid: false,
      newFieldUuid: uuidv4(),
      step: 1,
      tabs: [
        'BasicInfo',
      ],
      captions: {
        'BasicInfo': 'Basic Details',
      },
      basicFormFields: {
        firstName: null,
        surname: null,
        gender: null,
        birthDate: null,
        familySize: null,
        yearsFarming: null,
        transport: null,
        livelihood: null,
        farmerImageUuid: null,
        ethnicity: {
          options: ['Bambukushu', 'Basarwa', 'Bayei','Badxeriku','Batawana','Bakalaka','Banyemba','Baweku','Basubiya'],
          answer: null
        },
        region: {}
      },
    };
  },

  computed: {
    fieldAgentId() { return this.$store.getters.settings.fieldAgentId; },
    complete() { return [] },
    isStart() { return this.step === 1; },
    isLastStep() { return this.step === 9; },
    msisdn() { return this.customer.msisdn; },
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "100%";
        case "md":
          return "80%";
        case "lg":
          return "80%";
        case "xl":
          return "80%";
      }
    },
  },

  methods: {
    stepChange(to) {
      this.firstStep = (to=='first');
      this.lastStep = (to=='last');
    },

    isBasicFormFieldsValid() {
      return Object.values(this.basicFormFields).filter(basic => {return basic === null}).length === 0
          && !!this.basicFormFields.ethnicity.answer
          && !!this.basicFormFields.region
    },

    nextStep() {
      this.$refs.stepper.nextStep();
    },

    previous() { this.step -= 1; },

    toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customer.uuid } }); },
    toFieldSurveyForm() {
      this.$router.push({ name: 'EcoExistBwFieldSurvey', query: { uuid: this.customer.uuid, farmUuid: this.newFieldUuid } });
    },

    saveBasicInfo() {
      this.customer.fieldAgent = {id : this.fieldAgentId}
      this.customer.firstName = this.basicFormFields.firstName
      this.customer.surname = this.basicFormFields.surname
      this.customer.demographic.gender = this.basicFormFields.gender
      this.customer.demographic.region = this.basicFormFields.region
      this.customer.demographic.ethnicity = this.basicFormFields.ethnicity.answer
      this.customer.demographic.birthdate = this.basicFormFields.birthDate
      this.customer.demographic.dependents = this.basicFormFields.familySize

      this.customer.name = `${this.customer.firstName} ${this.customer.surname}`
      this.customer.economic = this.customer.economic || {};
      this.customer.economic.payload = this.customer.economic.payload || {};

      const { yearsFarming, familySize, transport, livelihood } = this.basicFormFields;
      this.customer.economic = { yearsFarming, familySize, transport, livelihood };

      if (!this.customer.fields) {
        this.customer.fields = []
      }

      this.save()

      this.snackbar = true;
    },

    validate() {
      this.$refs.basicInfoForm.validate();
    },
    
    saveAndDone() {
      this.validate()
      if (this.isBasicFormFieldsValid()) {
        this.saveBasicInfo();
        this.toCustomer();
      }
    },
    saveAndGoToFieldSurvey() {
      this.validate()
      if (this.isBasicFormFieldsValid()) {
      this.saveBasicInfo();
      this.toFieldSurveyForm();
      }
    },
    save() {
      this.snackbar = true;
      this.$store.dispatch('upsertCustomer', this.customer);
    },
  },

  mounted() {
    this.customer.uuid = this.customer.uuid || uuidv4();
    this.customer.msisdn = this.customer.msisdn || undefined;
    this.customer.isAFarmer = true;
  },

}
</script>
